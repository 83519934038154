export default {
  main:[
    { 
      item_name:'教室に関して',
      item_description:'教室内にアルコール除菌液と体温計を設置させていただいております。到着時は検温と手指の消毒をお願いします。\nレッスンの空き時間に教室内の換気をしてお迎えいたします。',
    },
    { 
      item_name:'インストラクターに関して',
      item_description:'インストラクターはお迎え前はもちろんのこと、日常的に手指の消毒・検温、手洗いうがいを徹底いたします。\n指導中はクリアマウスシールドを着用し、直接お体に触れてのポーズ指導は控えさせていただきます。',
    },
    { 
      item_name:'マットに関して',
      item_description:'教室内の除菌液で毎レッスン前、ご自身でお使いになるマットの清掃をお願いしております。\nレッスン後はインストラクターが清掃いたします。\nマットは清潔に保つように心がけておりますが、気になる方はバスタオルの使用を推奨いたします。',
    },
    { 
      item_name:'ご協力のお願い',
      item_description:'到着時は手指の消毒と検温をお願いしております。\n検温にて37℃以上の熱がある場合は入室をご遠慮いただきます。\n教室内ではマスクをご着用ください。（レッスン時は気になる方はご着用ください）\n万全を期すため、お越しになる前・お帰りになったあとの手洗い・うがい・手指の消毒を推奨いたします。',
    },
  ]
};