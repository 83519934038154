import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import React, { useState, useEffect } from 'react';
// import { Link } from "gatsby"

// import { Helmet } from "react-helmet"
import info from "../config/config"
import Map from "./googlemaps"
import Button from "../components/button"


const Widgets = ({ type }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)
	// const myFB = React.createRef();
	// const [size,setSize] = useState(500);
	// function debounce(fn, ms) {
	// 	let timer
	// 	return () => {
	// 		clearTimeout(timer)
	// 		timer = setTimeout(_ => {
	// 			timer = null
	// 			fn.apply(this, arguments)
	// 		}, ms)
	// 	};
	// };
	// useEffect(() => {
	// 	setSize( myFB.current.offsetWidth );
	// }, [myFB]);
	// useEffect(() => {
	// 	const debouncedHandleResize = debounce(function handleResize() {
	// 		setSize( myFB.current.offsetWidth );
	// 	}, 1000);
  //   window.addEventListener('resize', debouncedHandleResize)
  //   return () => window.removeEventListener('resize', debouncedHandleResize)
  // });
	return (
		<div className="tWidgetWrap">
				<Map />
				<ul className="cBtnList aR"><li>
					{/* <a href="https://g.page/r/CYcMZ-4X6LLfEAE" target="_blank" rel="noreferrer"> */}
					<a href="https://goo.gl/maps/1Bg2fEnrPGNNQFNBA" target="_blank" rel="noreferrer">
						<Button genre="secondary" icon="blank">GoogleMaps</Button>
					</a>
				</li></ul>
				<div className="cInfo">
				<ul>
					<li>
						<dl>
							<dt className="tableHeader2">教室名</dt>
							<dd className="text">{site.siteMetadata.title} </dd>
						</dl>
					</li>
					<li>
						<dl>
							<dt className="tableHeader2">所在地</dt>
							<dd className="text">{info.zipcode} {info.address1}<br/>
							<span className="textSmall">（{info.address2}）</span>
							</dd>
						</dl>
					</li>
					<li>
						<dl>
							<dt className="tableHeader2">目印</dt>
							<dd className="text">{info.info1}</dd>
						</dl>
					</li>
					<li>
						<dl>
							<dt className="tableHeader2">駐車スペース</dt>
							<dd className="text">{info.info2}</dd>
						</dl>
					</li>
					<li>
						<dl>
							<dt className="tableHeader2">交通アクセス</dt>
							<dd className="text">{info.info3}</dd>
						</dl>
					</li>
					<li>
						<dl>
							<dt className="tableHeader2">代表者</dt>
							<dd className="text">{info.info4}</dd>
						</dl>
					</li>
					<li>
						<dl>
							<dt className="tableHeader2">その他</dt>
							<dd className="text">{info.info5}</dd>
						</dl>
					</li>
				</ul>
			</div>
		</div>
	)
}

Widgets.propTypes = {
  type: PropTypes.string,
}

Widgets.defaultProps = {
  type: ``,
}

export default Widgets
