import PropTypes from "prop-types"
import React, {useState} from "react"

import Image from "../components/image"
import Protection from "../components/protection"

const Details = ({ type }) => {
  const [open, setOpen] = useState(false);
  const toggleFunc = () => {
    open?setOpen(false):setOpen(true);
  };

  return (
		<div id="care" className="cInnerArea">
      <div className="cProtect">
        <h4 // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={open?"pTitle open":"pTitle"}>
          <button type='button' onClick={toggleFunc} onKeyDown={toggleFunc}><span className="textBold">新型コロナウイルス感染症<br className="cSP"/>拡大予防対策について</span>
          <span className={"ico"}>▼</span></button>
        </h4>
        <div className={open?"pTextArea open":"pTextArea"}>
          <ul className="cThumbs">
            <li className={`test ${open && "open"} `}><Image filename="care_thumb-0@2x.jpg" alt="" /></li>
            <li><Image filename="care_thumb-1@2x.jpg" alt="" /></li>
            <li><Image filename="care_thumb-2@2x.jpg" alt="" /></li>
            <li><Image filename="care_thumb-3@2x.jpg" alt="" /></li>
          </ul>
          <Protection></Protection>
          {/* <p className="pClose" onClick={toggleFunc} onKeyDown={toggleFunc}>×　閉じる</p> */}
        </div>
      </div>
    </div>
	)
}

Details.propTypes = {
  type: PropTypes.string,
}

Details.defaultProps = {
  type: ``,
}

export default Details
