// import React, { useState } from "react"
import React from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"
import { Link as SLink } from 'react-scroll';

const Header = ({ type }) => {
	// const [navbarOpen, setNavbarOpen] = useState(false)
	return(
  <>
	<header id="sticker">
		<p><SLink role="button" to="contact" spy={true} smooth={true} duration={800} >お問い合わせ</SLink></p>
	</header>
  </>
	)
}


Header.propTypes = {
  type: PropTypes.string,
}

Header.defaultProps = {
  type: ``,
}

export default Header

// const ToggleArea = styled.div`
// 	.top { transform: ${props => (props.open ? "rotate(-45deg) translateY(0px)" : "translateY(-10px)")};}
// 	.middle { transform: ${props => (props.open ? "rotate(-45deg) translateY(0px)" : "translateX(0px)")};}
// 	.bottom { transform: ${props => (props.open ? "rotate(-45deg) translateY(0px)" : "translateY(10px)")};}
// 	/* @media #{$sp} { */
// 	@media (max-width: 768px) {
// 		display: block;
// 	}
// 	@media #{$pc} {
// 		display: none;
// 	}

// `
