import React from "react"
// import { Link } from "gatsby"
import { Link as SLink } from 'react-scroll';

import Layout from "../components/layout"
import Image from "../components/image"
import SimpleSlider from "../components/slick"
import Button from "../components/button"
import Header from "../components/header"
import Widgets from "../components/widgets"
import Care from "../components/care"
import SEO from "../components/seo"
import info from "../config/config"

const IndexPage = () => (
  <Layout type="home">
    <SEO title="イングリッシュ ヨーガ 春日町984教室" />
    <Header type="home" />
    <div id="content">
      <section id="mv" className="cSection">
        <SimpleSlider>
          <figure className="bgSlide bgSlide0"></figure>
          <figure className="bgSlide bgSlide1"></figure>
          <figure className="bgSlide bgSlide2"></figure>
          <figure className="bgSlide bgSlide3"></figure>
        </SimpleSlider>
        <div className="kvCover"><img src="/svg/bg_kv2.svg" alt="" /></div>
        <p id="headerLogo" className={`logo`}>
          <SLink role="button" to="mv" spy={true} smooth={true} duration={800}>
            <img src="/svg/sitelogo.svg" alt="英語で楽しく遊び心ヨーガ☆ Now or Never!" />
          </SLink>
	      </p>

        <p className="mvLogo">
          <img src="/svg/tagline.svg" alt="英語で楽しく遊び心ヨーガ☆ Now or Never!" />
        </p>
        <div className="mvCampaign">
          <h2><img src="/svg/kv_title.svg" alt="英会話Class開講！" /></h2>
          <dl>
            <dt className="textBold">英会話専門のクラスが開講しました！教室の最新情報は、現在英会話クラスのサイトで発信しています！</dt>
            <dd><Image filename="chiha_english_qr@2x.png" alt="" /></dd>
            </dl>
          <p className="btn1">
          <a href="https://chiha.school" rel="noreferrer">
                <Button>英会話クラス サイトへ</Button>
                </a>
              </p>
        </div>

      </section>
      <section id="about" className="cSection">
        <h2 className="cTitle"><img src="/svg/title1.svg" alt="教室について" /></h2>
        <h3 className="cTitle2"><img src="/svg/title1_1.svg" alt="ご挨拶" /></h3>
        <div id="greetings" className="cInnerArea">
          <h4 className="title"><img src="/svg/about.svg" alt="イングリッシュ ヨーガ 春日町984教室は、英語のレクチャーでヨーガを楽しむ、新感覚のヨーガ教室です。" /></h4>
          <ul className="cThumbs">
            <li><Image filename="about_thumb-0@2x.jpg" alt="" /></li>
            <li><Image filename="about_thumb-1@2x.jpg" alt="" /></li>
            <li><Image filename="about_thumb-2@2x.jpg" alt="" /></li>
            <li><Image filename="about_thumb-3@2x.jpg" alt="" /></li>
          </ul>
          <p className="text">英語+ヨーガ…とは言っても最初はどちらもわからなくて大丈夫。<br />
          遊び心のある直感的なプログラムとインストラクターがあなたを導きます。<br />
          キッズ・大人の二種類のクラスで、それぞれに合わせたオリジナルプログラムを作成して皆様をお待ちしております。</p>
        </div>
        <Care />
        
        <div id="instructor">
          <h3 className="cTitle2"><img src="/svg/title1_2.svg" alt="インストラクター" /></h3>
          <div className="cInnerArea">
            <div className="commentArea">
              <img className="bg_balloontail" src="/svg/bg_balloontail.svg" alt="" />
              <img className="bg_balloon" src="/svg/bg_balloon.svg" alt="" />
              <SimpleSlider>
                <p><img src="/svg/comment1.svg" alt="Hello!みなさん! イングリッシュヨーガ インストラクターのChihaです。" /></p>
                <p><img src="/svg/comment2.svg" alt="楽しく英語でヨーガしてみませんか?" /></p>
                <p><img src="/svg/comment3.svg" alt="続ければ心と身体は変わる! Let us enjoy doing yoga in English☆!!!" /></p>
              </SimpleSlider>
            </div>
            <figure className="thumb"><Image filename="prof_chiha@2x.jpg" alt="" /></figure>
              <dl className="chiha">
                <dt className="title"><img src="/svg/text1_2.svg" alt="" /></dt>
                <dd className="note">20代の頃よりヨーガを習い始める。英語では現在、J-shine（小学校英語指導者資格）の資格を生かし、小学校英語指導補助員として活動しているかたわら、10年以上ヨーガを続ける。ヨーガの楽しさとワクワクを伝えるため、2020年、ヨーガインストラクターの資格を取得。2021年よりイングリッシュ ヨーガ 春日町984教室をスタート。</dd>
              </dl>
              <dl className="pooh">
                <dt className="title">ぷーちゃん</dt>
                <dd><p className="note">教室敷地内に住む家ねこ。ヨーガが得意。普段は自由気ままに暮らしているが、仲良しの子やねこ好きの人の気配を感じるとたまに顔を出す。</p>
                <figure><Image filename="prof_pooh@2x.png" alt="" /></figure>
                </dd>
              </dl>
          </div>
        </div>
      </section>
      <section id="class" className="cSection">
        <h2 className="cTitle"><img src="/svg/title2.svg" alt="クラス" /></h2>
        <div id="adultsClass" className="cRecommends">
          <div className="cInnerArea">
            <figure className="rThumb"><Image filename="img_adults@2x.jpg" alt="" /></figure>
            <div className="rTextArea">
              <h4 className="rTitle"><img src="/svg/catch_adults.svg" alt="" /></h4>
              <p>ふっと日常から解き放たれる、元気をチャージしていただけるようなお教室を目指して、独自にプログラムを作成しています。</p>
              <p>ヨーガも英語も最初はわからなくて大丈夫。年齢も経験も関係ありません。</p>
              <p>英語＋ジェスチャー＋ポージングの実践でChihaがサポートします！</p>
              <ul className="cBtnList">
                <li> <SLink role="button" to="timetable" spy={true} smooth={true} duration={800}><Button>スケジュール</Button></SLink> </li>
                <li> <SLink role="button" to="fee" spy={true} smooth={true} duration={800}><Button genre="secondary">お月謝</Button></SLink> </li>
              </ul>
          </div>
            <div className="rMessageBox">
              <dl>
              <dt className="rMessageTtl">
                <span className="title"><img src="/svg/boxtitle2_2.svg" alt="" /></span><br/>
                <span className="textSmall">よくあるこんな疑問、お聞かせください。</span>
              </dt>
              <dd className="rMessageVoice1"><p className="textBold">身体が硬い人でも<br/>大丈夫かなぁ…?</p></dd>
              <dd className="rMessageVoice2"><p className="textBold">最近流行り？<br/>マインドフルネスって何？</p></dd>
              <dd className="rMessageVoice3"><p className="textBold">リラックスしてヨーガを<br/>受けるコツって<br/>あるのかなぁ…？</p></dd>
              </dl>
            </div>
          </div>
          <figure className="bgCat"><Image filename="bg_cat3@2x.png" alt="" /></figure>
        </div>
        <div id="kidsClass" className="cRecommends">
          <div className="cInnerArea">
            <figure className="rThumb"><Image filename="img_kids@2x.jpg" alt="" /></figure>
            <div className="rTextArea">
              <h4 className="rTitle"><img src="/svg/catch_kids.svg" alt="" /></h4>
              <p>キッズの興味を引くような毎回違う角度からアプローチする多彩な内容。元気を発散してもらえるようなお教室を目指して、遊び心あふれる独自のプログラムを作成しています。</p>
              <p>遊び感覚でヨーガや英語に触れて、学校や塾とはまた一味違ったワクワクを一緒に育てて行きましょう！</p>
              <ul className="cBtnList">
                <li> <SLink role="button" to="timetable" spy={true} smooth={true} duration={800}><Button>スケジュール</Button></SLink> </li>
                <li> <SLink role="button" to="fee" spy={true} smooth={true} duration={800}><Button genre="secondary">お月謝</Button></SLink> </li>
              </ul>
            </div>
            <div className="rMessageBox">
              <dl>
              <dt className="rMessageTtl">
                <span className="title"><img src="/svg/boxtitle2_1.svg" alt="" /></span><br/>
                <span className="textSmall">伝えたいことがたくさん！ぜひ教室に来てね！</span>
              </dt>
              <dd className="rMessageVoice1"><p className="textBold">元気な子もおとなしい子も！<br/>体を動かして、<br/>いっぱい笑おう！</p></dd>
              <dd className="rMessageVoice2"><p className="textBold">英語もヨーガも<br/>意識せず<br/>自然に触れていくと…？</p></dd>
              <dd className="rMessageVoice3"><p className="textBold">英語+ヨーガって<br/>楽しいよ？だってね…</p></dd>
              </dl>
            </div>
            <figure className="bgCat"><Image filename="bg_cat1@2x.png" alt="" /></figure>
          </div>
        </div>

      </section>
      <section id="timetable" className="cSection">
        <h2 className="cTitle"><img src="/svg/title3.svg" alt="スケジュール" /></h2>
        <div className="cInnerArea withPB">
            <h4>受付可能クラスは時期により異なります。</h4>
            <h4>最新の空き状況はお電話またはメールにてご確認ください。<br/><br/></h4>
        </div>
      </section>
      <section id="fee" className="cSection">
        <h2 className="cTitle"><img src="/svg/title4.svg" alt="お月謝" /></h2>
        <div className="cInnerArea withPB">
          <div className="cItemList">
            <ul>
              <li>
                <div className="figureArea">
                  <figure>
                  <Image filename="thumb_pricing-2@0.5x.png" alt="" />
                    </figure>
                  <figure>
                    <Image filename="thumb_pricing-3@0.5x.png" alt="" />
                  </figure>
                  <figure>
                    <Image filename="thumb_pricing-1@0.5x.png" alt="" />
                  </figure>
                </div>
                <dl>
                  <dt className="tableHeader">プライベート<span className="textSmall">※1</span>ヨーガ
                    <span className="textSmall notice2">（1回 60分 / 月4回ほど / 曜日・時間固定制 ※2）</span>
                  </dt>
                  <dd className="cat">
                    <span className="textBold">大人</span>
                    <span className="price_unit">￥</span>
                    <span className="price_month">2,000</span>
                    <span>/回　</span><br className="cSP"/>
                    <span className="textBold">子ども</span>
                    <span className="price_unit">￥</span>
                    <span className="price_unit">1,000</span>
                    <span>/回 </span><span className="textSmall">※3</span>
                 </dd>
                </dl>
                <ul className="note">
                  <li>※1 参加者は1〜3名・親族や知人であること</li>
                  <li>※2 入会時にレッスンの曜日と時間を決定します</li>
                  <li>※3 大人の参加が必須です（子どものみは不可）</li>
                  <li>※ 初回体験も同額で受講いただけます。詳しくはお問い合わせください</li>
                </ul>
              </li>
            </ul>
            </div>
        </div>
      </section>
      <section id="join" className="cSection">
        <h2 className="cTitle"><img src="/svg/title5.svg" alt="ご入会について" /></h2>
        <div className="cInnerArea withPB">
          <p>持ち物など詳しくはお電話にてお問い合わせください。</p>
            <ul className="note">
              <li>※ メールでのお問い合わせにはご返信までに数日のお時間を頂戴しております。お急ぎの場合はお電話をおすすめいたします。</li>
              <li>※ 男性でご入会の場合はご紹介者様もしくは男女ペアでの申し込みのどちらかの条件が必要となります。</li>
            </ul>
        </div>
      </section>
      <section id="access" className="cSection">
        <h2 className="cTitle"><img src="/svg/title6.svg" alt="アクセス" /></h2>
        <div className="cInnerArea">
          <Widgets/>
          <figure className="bgCat"><Image filename="bg_cat2@2x.png" alt="" /></figure>
        </div>
      </section>
      <section id="contact" className="cSection">
        <div className="cInnerArea">
          <h2 className="title"><img src="/svg/contact_text.svg" alt="ご不明な点は お気軽にお問い合わせください" /></h2>
          <p className="text">詳しくはお電話、またはメールにてお問い合わせください。<br/>
          お急ぎの場合やどちらでも良い場合はお電話がおすすめです。<br/>
          メールはご返信までに数日頂戴しております。あらかじめご了承ください。
          </p>
          <ul className="cBtnList">
            <li>
            <a className="cAlpha" href={`tel:${info.tel}`}><Button icon="tel">TEL</Button></a>
            </li>
            <li>
            <a className="cAlpha" href={`mailto:${info.mail}`}><Button icon="mail" genre="secondary">E-MAIL</Button></a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default IndexPage
