import PropTypes from "prop-types"
import React from "react"

import items from "../config/textProtection"

function SelectRowTable({items}) {
  return (
    <ul className="pList">
      {items.map((row,index) => (
        <li key={index+1} >
          <dl>
              <dt className="title">{row.item_name}</dt>
              <dd className="text">{row.item_description}</dd>
          </dl>
        </li>
      ))}
    </ul>
  )
}

const Details = ({ type }) => {
	return (
		<SelectRowTable items={items.main}></SelectRowTable>
	)
}

Details.propTypes = {
  type: PropTypes.string,
}

Details.defaultProps = {
  type: ``,
}

export default Details
