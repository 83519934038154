import React, { Component } from 'react';
import styled from 'styled-components'
// import GoogleMapReact from 'google-map-react'
 
// const AnyReactComponent = ({ text }) => <GreatPlaceStyle><span>{text}</span></GreatPlaceStyle>;

// const createMapOptions = (maps) => {
// 	return {
// 		mapTypeControlOptions: {
// 			position: maps.ControlPosition.TOP_RIGHT,
// 		},
// 		mapTypeControl: false,
// 		zoomControl: true,
// 		scaleControl: false,
// 		streetViewControl: false,
// 		fullscreenControl: false,
// 		// styles: [
// 		// 	{
// 		// 		featureType: 'water',
// 		// 		elementType: 'geometry',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#e9e9e9',
// 		// 			},
// 		// 			{
// 		// 				lightness: 20,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'landscape',
// 		// 		elementType: 'geometry',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#f5f5f5',
// 		// 			},
// 		// 			{
// 		// 				lightness: 20,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'road.highway',
// 		// 		elementType: 'geometry.fill',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#ffffff',
// 		// 			},
// 		// 			{
// 		// 				lightness: 17,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'road.highway',
// 		// 		elementType: 'geometry.stroke',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#ffffff',
// 		// 			},
// 		// 			{
// 		// 				lightness: 29,
// 		// 			},
// 		// 			{
// 		// 				weight: 0.2,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'road.arterial',
// 		// 		elementType: 'geometry',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#ffffff',
// 		// 			},
// 		// 			{
// 		// 				lightness: 18,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'road.local',
// 		// 		elementType: 'geometry',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#ffffff',
// 		// 			},
// 		// 			{
// 		// 				lightness: 16,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'poi',
// 		// 		elementType: 'geometry',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#f5f5f5',
// 		// 			},
// 		// 			{
// 		// 				lightness: 21,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'poi.park',
// 		// 		elementType: 'geometry',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#dedede',
// 		// 			},
// 		// 			{
// 		// 				lightness: 21,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		elementType: 'labels.text.stroke',
// 		// 		stylers: [
// 		// 			{
// 		// 				visibility: 'on',
// 		// 			},
// 		// 			{
// 		// 				color: '#ffffff',
// 		// 			},
// 		// 			{
// 		// 				lightness: 16,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		elementType: 'labels.text.fill',
// 		// 		stylers: [
// 		// 			{
// 		// 				saturation: 36,
// 		// 			},
// 		// 			{
// 		// 				color: '#333333',
// 		// 			},
// 		// 			{
// 		// 				lightness: 40,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		elementType: 'labels.icon',
// 		// 		stylers: [
// 		// 			{
// 		// 				// visibility: 'off',
// 		// 				visibility: 'on',
// 		// 			},
// 		// 			// {
// 		// 			// 	color: '#707F89',
// 		// 			// },
// 		// 			{
// 		// 				lightness: 70,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'transit',
// 		// 		elementType: 'geometry',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#f2f2f2',
// 		// 			},
// 		// 			{
// 		// 				lightness: 19,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'administrative',
// 		// 		elementType: 'geometry.fill',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#fefefe',
// 		// 			},
// 		// 			{
// 		// 				lightness: 20,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// 	{
// 		// 		featureType: 'administrative',
// 		// 		elementType: 'geometry.stroke',
// 		// 		stylers: [
// 		// 			{
// 		// 				color: '#fefefe',
// 		// 			},
// 		// 			{
// 		// 				lightness: 17,
// 		// 			},
// 		// 			{
// 		// 				weight: 1.2,
// 		// 			},
// 		// 		],
// 		// 	},
// 		// ],
// 	}
// }
class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 34.3290089,
			lng: 134.0891192
    },
    zoom: 17
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <MapContainer>
			<iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3294.8433383293964!2d134.08692515052707!3d34.329008880435964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3553eb358e015d47%3A0xdfb2e817ee670c87!2z44Kk44Oz44Kw44Oq44OD44K344OlIOODqOODvOOCrCDmmKXml6XnlLo5ODTmlZnlrqQ!5e0!3m2!1sja!2sjp!4v1672890671798!5m2!1sja!2sjp" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        {/* <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCi2mzhwMPyv5axFjHlZ-j_omy50xy_SJw' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
					options={createMapOptions}
        >
          <AnyReactComponent
            lat={34.3290089}
        		lng={134.0891192}
            text={<img src="/svg/favicon.svg" alt="" />}
          />
        </GoogleMapReact> */}
      </MapContainer>
    );
  }
}
 
export default SimpleMap;

const MapContainer = styled.div`
  height: 350px;
	width: 100%;
	margin-bottom: 24px;
	overflow: hidden;
	iframe {
		width: 100%;
		height: 100%;
	}
`
// // initially any map object has left top corner at lat lng coordinates
// // it's on you to set object origin to 0,0 coordinates
// const GreatPlaceStyle = styled.div`
//  width: 30px;
//   height: 30px;
//   border-radius: 50% 50% 50% 0;
//   background: #BDB0E1;
//   position: absolute;
//   transform: rotate(-45deg);
//   left: 50%;
//   top: 50%;
//   margin: -20px 0 0 -20px;
//   animation-name: bounce;
//   animation-fill-mode: both;
// 	animation-duration: 1s;
// 	line-height:0;
// 	span {
// 		position:absolute;
// 		margin: -3.2em 0 0;
// 		line-height: 1;
// 		width:6em;
// 		height:6em;
// 		display: block;
// 		transform: rotate(45deg);
// 		z-index:1;
// 	}
// 	/* // アイコン周囲 円 */
//   &:after{
//     content: '';
//     width: 22px;
//     height: 22px;
//     margin: 4px 0 0 4px;
//     background: #FFFFF1;
//     position: absolute;
// 		border-radius: 50%;
// 		z-index:0;
//     /* content: '';
//     width: 3em;
//     height: 3em;
//     margin: -0.25em 0 0 0;
// 		background:#FFFFF1;
//     position: absolute;
// 		border-radius: 50%;
// 		border:2px solid #BDB0E1;
//  */
// 		z-index:0;
// 	}
// 	@keyframes bounce {
// 		0%{
// 			opacity: 0;
// 			transform: translateY(-2000px) rotate(-45deg);
// 		}
// 		60%{
// 			opacity: 1;
// 			transform: translateY(30px) rotate(-45deg);
// 		}
// 		80%{
// 			transform: translateY(-10px) rotate(-45deg);
// 		}
// 		100%{
// 			transform: translateY(0) rotate(-45deg);
// 		}
// 	}

// `
